.section-top-page {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
  margin-top: 2rem;
  padding-bottom: 2.5rem;
}

.main-article-section {
  grid-template-columns: 7fr 3fr;
}

.main-article-section,
.side-article-section {
  display: grid;
  gap: 10px;
}

.main-article-section h3 {
  margin-top: 10px;
}

.main-article-section h3,
.side-article-content h3 {
  margin-bottom: 10px;
}

.main-article-section-group {
  border-right: 1px solid #ddd;
  padding-right: 1rem;
}

.side-article-section {
  grid-template-rows: repeat(2, 1fr);
}

.side-article-section-card:last-child {
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.section-bottom-page {
  display: flex;
  justify-content: center;
}

.bottom-article-section {
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.top-side-article {
  display: flex;
  gap: 20px;
  align-items: center;
}

.top-side-article > img {
  max-width: 8rem;
  height: auto;
}

.bottom-article-section {
  border-top: 1px solid #ddd;
  padding-top: 1rem;
}

.bottom-article-card {
  padding: 15px;
  border-right: 1px solid #ddd;
  width: 20%;
}

.img-bottom-container img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.bottom-article-card:last-child {
  border-right: none;
}

.bottom-article-inner-group p {
  margin-top: 1.5rem;
}

.bottom-article-inner-group h3 {
  display: flex;
  text-align: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .section-top-page {
    grid-template-columns: 1fr;
    padding: 1rem;
    margin-top: 1rem;
  }

  .section-bottom-page {
    padding: 1rem;
  }

  .section-top-page h3,
  .section-bottom-page h3 {
    font-size: 16px;
  }

  .section-top-page p,
  .section-bottom-page p {
    line-height: 1.4;
  }

  .section-bottom-page p {
    font-size: 1.1rem;
  }

  .main-article-section {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }

  .main-article-section-group {
    border-right: none;
  }

  .side-article-section-card:last-child {
    border-top: none;
    padding-top: 0;
  }

  .side-article-content > h3 {
    padding-top: 1rem;
  }

  .bottom-article-card {
    padding-right: 0.6rem;
    width: 100%;
    border-right: none;
  }

  .bottom-article-card:last-child {
    padding-right: 0;
  }

  .bottom-article-inner-group p {
    margin-top: 1rem;
  }

  .bottom-article-inner-group section {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .bottom-article-inner-group .article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-bottom-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .section-top-page h3,
  .section-bottom-page h3 {
    font-size: 14px;
  }

  .section-top-page p,
  .section-bottom-page p {
    line-height: 1.3;
  }

  .section-bottom-page p {
    font-size: 13px;
  }

  .section-top-page {
    grid-template-columns: 1fr;
    padding: 0 1rem;
    margin-top: 0;
  }

  .bottom-article-inner-group section {
    width: 90%;
  }

  .main-article-section {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }

  .main-article-section-group {
    border-right: none;
  }

  .side-article-content {
    padding: 0.5rem;
  }

  .side-article-content > h3 {
    padding-top: 1rem;
  }

  .section-bottom-page {
    padding: 1rem;
  }

  .bottom-article-section {
    gap: 7px;
  }

  .bottom-article-card {
    padding: 0;
  }
}