.footer-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid #ddd;
  margin: 1rem 2rem;
}

.footer-container > span {
  color: grey;
  padding: 1.5rem 0.7rem;
}

.footer-container > span:first-of-type {
  padding-left: 0;
  background-image: none;
}

@media (max-width: 1024px) {
  .footer-container {
    flex-direction: row;
    align-items: center;
    margin: 1rem;
  }

  .footer-container > span:first-of-type {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-container > span:not(:first-of-type) {
    flex-basis: fill;
    padding: 0.5rem;
    font-size: 12px;
  }
}

