*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-container {
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

h3 {
  font-weight: 700;
  font-size: 18px;
}

p, h3 {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

p, span {
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
}

span:nth-of-type(1) {
  background-image: url('data:image/svg+xml,%3Csvg width="3" height="3" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle cx="1.5" cy="1.5" r="1" fill="gray" /%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-size: 5px;
  background-position: 0 50%;
  padding-left: 10px;
}

a:link {
  color: black;
}

a:visited {
  color: black;
}

.article h3 {
  text-decoration: none;
  transition: text-decoration 0.3ease;
}

.article:hover h3 {
  text-decoration: underline;
  color: darkgray;
}

.img-container-home,
.img-container-section,
.img-container-show {
  transition: transform 0.3 ease;
}

.img-container-home:hover,
.img-container-section:hover,
.img-container-show:hover {
  transform: scale(1.05);
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1300px) {
  .main-container {
    width: 95%;
  }
}